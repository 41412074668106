import React from 'react'
import './global.css';
import './servicos2.css';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";

function Interior() {
    const { language } = useParams();
    return (
        <>
            <div className="main spaceFooter">
                <div className='servicesWidth'>
                    <div className="header">

                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="button" display="block">
                                Serviços
                            </Typography>
                            <Divider className="dividerWidth" />
                            <Grid>
                                <Button variant="text" className="serviceBtnActive" >Interior</Button>
                            </Grid>
                            <Grid>
                                <Button variant="text" className="serviceBtn" href={`${language}/services/outdoor`}>Exterior</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={9}>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="contentInfo">
                                <Typography component="div" variant="h4">
                                    Interior
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    Somos líderes em soluções de aplicação de pavimentos interiores. 
                                    Com uma herança de excelência e instalação de pavimentos para uma variedade de espaços, desde residências luxuosas até escritórios de grande escala. 
                                    A nossa equipa é especializada e dedica se a proporcionar acabamentos excepcionais. 
                                    Através de nossa abordagem orientada para o cliente e a nossa paixão pela inovação, garantimos resultados duradouros em cada projeto.
                                </Typography>
                                <br />
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <li>Flutuantes</li>
                                    <li>Vinílicos em rolo, LVT e SPC</li>
                                    <li>Polimentos e envernizamentos</li>
                                    <li>Borrachas</li>
                                </Typography>
                            </Box>
                            <Grid container spacing={1} columns={2} className="services imgs">
                                <Grid item xs={12} md={1}>
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image={require("./../static/images/obras/obra1.JPG")}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image={require("./../static/images/obras/obra3.JPG")}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} columns={2} className="services imgssec2">
                                <Grid item xs={12} md={1}>
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image={require("./../static/images/obras/obra15.JPG")}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image={require("./../static/images/obras/obra19.JPG")}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            {/* </Card> */}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Interior;