import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import categories from './bd/categories';

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    let categoriesOnline = categories.filter(x => x.online);
    console.log("tt", this.props)
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" container light >
          <NavbarBrand tag={Link} to="/pt">
            <img
              src={require("./../static/images/logo8.png")}
              width="250"
              // height="40"
              className="d-inline-block align-top logoposition"
              alt="React Bootstrap logo"
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="option" to="pt/services/interior">Serviços</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="option">
                <DropdownToggle nav>
                  Produtos
                </DropdownToggle>
                <DropdownMenu center >
                  {categoriesOnline.map((category, index) => (
                    <DropdownItem tag={Link} className="option" to={`pt/products/${category.id}`}>{category.categoryName}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink tag={Link} className="option" to="pt/contacts">Contactos</NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}