import React from 'react';
import './servicos.css';

import Grid from '@mui/material/Grid';
import './global.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';

function Servicos() {
    const { language } = useParams()
    return (<>
        <div className="servicos">
            <div className="servicesTitle" style={{ textAlign: 'center' }}>
                {/* <h2>Conheça os nossos Serviços</h2> */}
                <Typography gutterBottom variant="h4" component="div">
                    Conheça os nossos Serviços
                </Typography>
                <hr className="divider" />
            </div>
            <Grid container spacing={2} columns={2} className="servicosCards">
                <Grid item xs={12} md={1}>
                    <Card className="list">
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="400"
                            image={require("./../static/images/exterior_v5.jpg")}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Exterior
                            </Typography>
                            <Typography gutterBottom variant="subtitle2" component="div">
                                A nossa empresa é profissional à mais de 25 anos na instalação de pavimentos exteriores. 
                                Desde espaços residenciais a complexos comerciais e o nosso compromisso é satisfazer as necessidades específicas de cada cliente. 
                                Estamos aqui para transformar as suas ideias de espaços exteriores em realidade.
                            </Typography>
                            <Typography gutterBottom variant="subtitle2" component="div">
                            </Typography>
                            <Link href={`${language}/services/outdoor`} underline="hover" className="link">
                                Ler mais
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={1}>
                    <Card className="list">
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="400"
                            image={require("./../static/images/interior_v2.jpg")}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Interior
                            </Typography>
                            <Typography gutterBottom variant="subtitle2" component="div">
                                Somos líderes em soluções de aplicação de pavimentos interiores. 
                                Com uma herança de excelência e instalação de pavimentos para uma variedade de espaços, desde residências luxuosas até escritórios de grande escala. 
                                A nossa equipa é especializada e dedica se a proporcionar acabamentos excecionais. 
                            </Typography>
                            <Typography gutterBottom variant="subtitle2" component="div">
                            </Typography>
                            <Link href={`${language}/services/interior`} underline="hover" className="link">
                                Ler mais
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    </>
    );
}

export default Servicos;