import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import React, { useRef } from 'react';
import './global.css';
import './contacts.css';
import Typography from '@mui/material/Typography';
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
//import translations from './bd/translation';
import { useParams } from "react-router-dom";

function Contacts() {
    const form = useRef();

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    let { language } = useParams();

    const sendEmail = (e) => {
        setLoading(true)
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
            .then(
                (result) => {
                    setLoading(false)
                    setOpenSuccess(true);
                    form.current.reset()
                },
                (error) => {
                    setLoading(false)
                    setOpenError(true);
                }
            );
    };
    // const x = {translations[language]["lbl_title"]}
    return (
        <>
            <div className='center'>
                <Box sx={{ flexGrow: 1 }} className="boxContacts">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3.5}>
                            <h1>Contactos</h1>
                            <hr className="dividerContacts" />
                            <div className="contactsInfo">
                                <Typography gutterBottom variant="h6" component="div">
                                    Aresta Perfil, Madeiras lda
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    Parque Industrial da Barca, Nº 445
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    4795-038 Santo Tirso                        </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <span className="textBold">Tlm: </span> (+351) 917324660
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <span className="textBold">Email: </span> arestaperfil@gmail.com
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8.5}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2249.2054245104496!2d-8.403927085427885!3d41.3796660044383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24f6ad9d37de57%3A0x1878fb361ada177!2sArestaperfil%20Madeiras%2C%20Lda.!5e1!3m2!1spt-PT!2spt!4v1673390021227!5m2!1spt-PT!2spt"
                                height="400"
                                style={{ border: 0 }}
                                loading="eager"
                                className="map">

                            </iframe>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }} className='form'>
                    <div className="servicesTitle" style={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant="h4" component="div">
                            Peça o seu orçamento.
                        </Typography>
                    </div>
                    <form className='cf spacingForm' ref={form} onSubmit={sendEmail}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <input type='text' placeholder='Nome' name='user_name' className='input-form' required />
                            </Grid>
                            <Grid item xs={6}>
                                <input type='email' placeholder='Email' name='user_email' className='input-form' required />
                            </Grid>
                            <Grid item xs={12}>
                                <textarea name='message' type='text' placeholder='Messagem' className='textarea-form' required />
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={openSuccess}>
                                    <Alert onClose={() => { setOpenSuccess(false); }} severity="success">Email enviado com sucesso.</Alert>
                                </Collapse>
                                <Collapse in={openError}>
                                    <Alert onClose={() => { setOpenError(false); }} severity="error">Erro no envio. Por favor tente mais tarde.</Alert>
                                </Collapse>
                                {loading ?
                                    <div className='div-loading'><CircularProgress className='form-loading' /></div> :
                                    <button type='submit' value='Enviar' id='input-submit' className='form-enviar' disabled={loading} > Enviar </button>}
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </div>
        </>
    )
}

export default Contacts;