let products = [
    {
        id: "2201",
        name: "Vinílico SPC K Nobre 2201",
        description: "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável. Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias. Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial.",
        categoryId: "1",
        categoryName: "Vinílicos",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2201/2201_1.jpg",
            "2201/2201_2.jpg",
            "2201/2201_3.jpg",
            "2201/2201_4.jpg",
            "2201/2201_cor.jpg"
        ],
        stock: 0,
        reference: "2201",
        online: true,
        createdate: "data",
        technicalinformationimg: "./../static/images/fichatecnica.jpg",
    },
    {
        id: "2202",
        name: "Vinílico SPC K Nobre 2202",
        description: "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável. Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias. Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial.",
        categoryId: "1",
        categoryName: "Vinílicos",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2202/2202_1.jpg",
            "2202/2202_2.jpg",
            "2202/2202_3.jpg",
            "2202/2202_4.jpg",
            "2202/2202_cor.jpg"
        ],
        stock: 0,
        reference: "2202",
        online: true,
        createdate: "data",
        technicalinformationimg: "./../static/images/fichatecnica.jpg",
    },
    {
        id: "2203",
        name: "Vinílico SPC K Nobre 2203",
        description: "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável. Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias. Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial.",
        categoryId: "1",
        categoryName: "Vinílicos",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2203/2203_1.jpg",
            "2203/2203_2.jpg",
            "2203/2203_3.jpg",
            "2203/2203_3.jpg",
            "2203/2203_cor.jpg"
        ],
        stock: 0,
        reference: "2203",
        online: true,
        createdate: "data",
        technicalinformationimg: "./../static/images/fichatecnica.jpg",
    },
    {
        id: "2204",
        name: "Vinílico SPC K Nobre 2204",
        description: "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável. Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias. Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial.",
        categoryId: "1",
        categoryName: "Vinílicos",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2204/2204_1.jpg",
            "2204/2204_2.jpg",
            "2204/2204_3.jpg",
            "2204/2204_4.jpg",
            "2204/2204_cor.jpg"
        ],
        stock: 6,
        reference: "2204",
        online: true,
        createdate: "data"
    },
    {
        id: "2205",
        name: "Vinílico SPC K Nobre 2205",
        description: "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável. Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias. Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial.",
        categoryId: "1",
        categoryName: "Vinílicos",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2205/2205_1.jpg",
            "2205/2205_2.jpg",
            "2205/2205_3.jpg",
            "2205/2205_4.jpg",
            "2205/2205_cor.jpg"
        ],
        stock: 6,
        reference: "2205",
        online: true,
        createdate: "data"
    },
    {
        id: "2206",
        name: "Vinílico SPC K Nobre 2206",
        description: "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável. Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias. Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial.",
        categoryId: "1",
        categoryName: "Vinílicos",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2206/2206_1.jpg",
            "2206/2206_2.jpg",
            "2206/2206_3.jpg",
            "2206/2206_cor.jpg"
        ],
        stock: 6,
        reference: "2206",
        online: true,
        createdate: "data"
    }
]

export default products;