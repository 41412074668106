import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import './global.css';
import './projetos.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
];

function Projetos() {
  return (
    <div className="projetos">
      <div className="servicesTitle" style={{ textAlign: 'center' }}>
        <h2></h2>
        <Typography gutterBottom variant="h4" component="div">
          Projetos Realizados
        </Typography>
        <hr className="projectDivider" />
      </div>
      <Grid container spacing={2} columns={3} className="services imgProject">
        <Grid item xs={12} md={1}>
          <Card className="list">
            <CardMedia
              component="img"
              height="400"
              image={require("./../static/images/obras/obra1.JPG")}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={1}>
          <Card className="list">
            <CardMedia
              component="img"
              height="400"
              image={require("./../static/images/obras/obra2.JPG")}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={1}>
          <Card className="list">
            <CardMedia
              component="img"
              height="400"
              image={require("./../static/images/obras/obra20.jpg")}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Projetos;