import './footer.css'
import './global.css';
import Grid from '@mui/material/Grid';
import './global.css';
import Typography from '@mui/material/Typography';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';

function Footer() {
    return (
        <div className="footerContainer">
            <div className="center">
                <Grid container spacing={2} columns={2} className="footerCards">
                    <Grid item xs={12} md={1}>
                        <Typography gutterBottom variant="h6" component="div">
                            Aresta Perfil, Madeiras lda
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div">
                            Parque Industrial da Barca, Nº 445
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div">
                            4795-038 Santo Tirso
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div">
                            <span className="textBold">Tlm: </span>(+351) 917324660
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div">
                            <span className="textBold">Email: </span>arestaperfil@gmail.com
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Typography gutterBottom variant="h6" component="div">
                            Siga-nos
                        </Typography>
                        <IconButton onClick={() => window.open('https://www.facebook.com/profile.php?id=100087453116441')} color="inherit" disableRipple={true} className="icons">
                            <FacebookOutlinedIcon fontSize="large" />
                        </IconButton>
                        <IconButton onClick={() => window.open('https://www.instagram.com/arestaperfil_k.nobre/?igshid=MmU2YjMzNjRlOQ%3D%3D')} color="inherit" disableRipple={true} className="icons">
                            <InstagramIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                    {/* <Grid item xs={12} md={1}>
                        <Typography gutterBottom variant="h6" component="div">
                            Estamos ao seu dispor a qualquer altura.
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div">
                            Contacte-nos para informações ou orçamentos.
                        </Typography>
                        <Link href="/contacts">
                            <button className='footerButton' >Orçamentos</button>
                        </Link>
                    </Grid> */}
                </Grid>
            </div>
        </div>
    )
}

export default Footer;