import React, { Component } from 'react';
import Galery from './Galery';
import Servicos from './Servicos';
import Projetos from './Projetos';
import Footer from './Footer';
import './global.css';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="test">
        <Galery />
        {/* <div className="center"> */}
          <Servicos />
        {/* </div> */}
          <Projetos />
        {/* <Footer/> */}
      </div>
    );
  }
}
