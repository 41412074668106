import React from 'react'
import './global.css';
import './servicos2.css';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";

function Exterior() {
    const { language } = useParams();

    return (
        <>
            <div className="spaceFooter">
                <div className='servicesWidth'>
                    <div className="header">

                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="button" display="block">
                                Serviços
                            </Typography>
                            <Divider className="dividerWidth" />
                            <Grid>
                                <Button variant="text" className="serviceBtn" href={`${language}/services/interior`}>Interior</Button>
                            </Grid>
                            <Grid>
                                <Button variant="text" className="serviceBtnActive">Exterior</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={9}>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="contentInfo">
                                <Typography component="div" variant="h4">
                                    Exterior
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    A nossa empresa é profissional à mais de 25 anos na instalação de pavimentos exteriores. 
                                    Desde espaços residenciais a complexos comerciais e o nosso compromisso é satisfazer as necessidades específicas de cada cliente. 
                                    Estamos aqui para transformar as suas ideias de espaços exteriores em realidade.
                                </Typography>
                                <br />
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <li>Deck</li>
                                </Typography>
                            </Box>
                            <Grid container spacing={1} columns={2} className="services imgs">
                                <Grid item xs={12} md={1}>
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image={require("./../static/images/obras/obra2.JPG")}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image={require("./../static/images/obras/obra4.JPG")}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            {/* </Card> */}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Exterior;