let categories = [
    {
        id: "1",
        categoryName: "Vinílicos",
        online: true
    },
    {
        id: "2",
        categoryName: "Painel de parede",
        online: false
    },
    {
        id: "3",
        categoryName: "Decks",
        online: false
    }
];

export default categories;